
import { mapState } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperclip, faCircle } from "@fortawesome/free-solid-svg-icons";
import { CkEditor } from "@/common/contracts/ckeditor";

library.add(faPaperclip, faCircle);

import { GET_EMAIL, RESET_EMAIL } from "@/store/actions.type";
import { defineComponent, State } from "vue";

declare var $: any;

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default defineComponent({
  name: "PrintScreen",
  components: {},
  props: {
    emailId: {
      type: String
    }
  },
  data() {
    return {
      currentStatus: null as any,
      editor: CkEditor.CustomClassicEditor,
      editorConfig: {
        startupFocus: true
      },

      loading: true,
      error: "",

      replying: false
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;
    this.reset();
  },
  computed: {
    ...mapState({
      folders: (state) => (state as State).folders.folders,
      folder: (state) => (state as State).folders.folder,
      email: (state) => (state as State).emails.email
    }),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  watch: {
    "$route.params.emailId": {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.$store.dispatch(RESET_EMAIL);
        } else if (oldVal !== newVal) {
          this.getEmail(newVal);
          this.reset();
        }
      },
      immediate: true
    }
  },
  methods: {
    getEmail(id, toggle = false) {
      if (toggle === true && (id === "" || this.email.id === id)) {
        this.$store.dispatch(RESET_EMAIL);
      } else {
        this.$store.dispatch(GET_EMAIL, { id: id }).then(() => {
          const emailBody = document.getElementById("email-body");
          if (emailBody != null) {
            emailBody.innerHTML = this.email.body ?? "";
            $.map(this.email.replies, function (item) {
              const itemElm = document.getElementById(item.id);
              if (itemElm != null) {
                itemElm.innerHTML = item.body;
              }
            });
          }
          window.print();
        });
      }
    },
    reset() {
      this.$store.dispatch(RESET_EMAIL);
      this.currentStatus = STATUS_INITIAL;
      this.replying = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!to.params.emailId) this.$store.dispatch(RESET_EMAIL);

    next();
  }
});
